import axios from 'axios';
import React, { useState } from "react";
import Header from "../components/Header";

const Register = () => {
    localStorage.clear();
    const baseURL = `${process.env.REACT_APP_API_URL}/auth/register`;
    console.log(process.env.REACT_APP_API_URL);
    const [email, setEmail ] = useState("");
    const [username, setUsername ] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");


    const setToken = (data) => {
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem('token', data.accessToken);

    }

    const getToken = () => {
        return localStorage.getItem('token');
    }

    const getUser = () => {
        return JSON.parse(localStorage.getItem('user'))
    }

    const register = (e) => {
        e.preventDefault();
        
        axios.post(baseURL, {
            email: email, 
            username: email,
            password: password
        }).then((response) => {
            if (response.data) {
                setMessage(response.data.msg);
            } else {
                setMessage(false);
            }

            // setToken(response.data);
            setUsername("");
            setEmail("");
            setPassword("");

        });
    }



    return <>
       <Header/>
       <form className="col-md-3 offset-md-4"  style={{ padding: '10px', border: '1px dashed #8bc34a'}} onSubmit={register}>
    
       { message!='' &&  <div className="alert alert-success" role="alert">{message}</div> }
    
       <h3>Create Account</h3>

        <div className="mb-3">
            <label for="exampleInputEmail1" className="form-label">Email address</label>
            <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}  required/>
            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
        </div>
        
        <div className="mb-3">
            <label for="exampleInputPassword1" className="form-label">Password</label>
            <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}  required/>
        </div>
        <div className="mb-3">
            <a href='/login'>Already have account?</a>
            {/* <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
            <label className="form-check-label" for="exampleCheck1">Check me out</label> */}
        </div>
        <button type="submit" className="btn btn-primary float-end">Create Account</button>
        </form>
    </>
}

export default Register;