import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CloseIcon from '@mui/icons-material/Close';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';

const KPI = (props) => {

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const CustomCard = styled(Paper)(({ theme }) => ({
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: theme.shadows[6],
        },
    }));

    const getLabel = (result) => {
        switch (result) {
            case 'PASS':
                return 'bg-success';
            case 'FAIL':
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    }

    return (
        <>
            <Modal open={open}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        height: "80vh",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 2,
                        outline: "none",
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #ddd", // Add border around the modal
                    }}
                >
                    <div style={{ 
                        position: "sticky", 
                        top: 0, 
                        display: "flex", 
                        justifyContent: "space-between", 
                        alignItems: "center",
                        backgroundColor: "#f5f5f5", // Light background for header
                        zIndex: 2,
                        padding: "15px 20px", // Add padding for better spacing
                        borderBottom: "1px solid #ddd", // Border at the bottom of the header
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
                    }}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                            {props.title}
                        </Typography>
                        <CloseIcon sx={{ cursor: "pointer", '&:hover': { color: 'red' } }} onClick={handleClose} />
                    </div>
                    <Box sx={{ overflowY: "auto", flexGrow: 1, p: 2 }}>
                        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: "#2196F3", color: "#fff" }}>
                                        <TableCell sx={{color: "#fff" }}><strong>Resource ID</strong></TableCell>
                                        <TableCell sx={{color: "#fff" }} align="center"><strong>Result</strong></TableCell>
                                        <TableCell sx={{color: "#fff" }} align="center"><strong>Severity</strong></TableCell>
                                        <TableCell sx={{color: "#fff" }} align="center"><strong>Risk</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.data.map((row, index) => (
                                        <TableRow
                                            key={row["Resource ID"]}
                                            sx={{
                                                '&:nth-of-type(odd)': { backgroundColor: "#f9f9f9" },
                                                '&:hover': { backgroundColor: "#e0e0e0" } // Hover effect for rows
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row["Resource ID"]}
                                            </TableCell>
                                            <TableCell align="center">
                                                <span className={`badge ${getLabel(row["Result"])}`}>
                                                    {row["Result"]}
                                                </span>
                                            </TableCell>
                                            <TableCell align="center">{row["Severity"]}</TableCell>
                                            <TableCell align="center">{row["Risk"]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Modal>
            <div className="col-md-3">
                <CustomCard>
                    <div className="card mb-4 rounded-3 shadow-sm">
                        <div className={`card-header py-3 ${props.color}`} style={{ color: '#fff', backgroundColor: '#007BFF' }}>
                            <h5 className="my-0 fw-normal">{props.title}</h5>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">
                                {props.value}<small className="text-muted fw-light">/{props.total}</small>
                            </h1>
                            <a href="#" onClick={(e) => { e.preventDefault(); handleOpen(); }} style={{ color: '#007BFF', textDecoration: 'none', fontWeight: 'bold' }}>View</a>
                        </div>
                    </div>
                </CustomCard>
            </div>
        </>
    );
}

export default KPI;
