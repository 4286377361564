import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import './index.css';
import App from "./components/App";
import LoginPage from "./routes/Login";
import Report from "./routes/Report";

import reportWebVitals from './reportWebVitals';
import Register from './routes/Register';
import Dashboard from './routes/Dashboard';
import ResetPassword from './routes/Reset';
import Account from './routes/Account';
import Analytics from './routes/Analytics';
import BuyCredit from './routes/BuyCredit';
import Order from './routes/Order';
import SecureDashboard from './routes/Secure/SecureDashboard';
import Protected from './components/Protected';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
   <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/register" element={<Register />}></Route>
      <Route path="/dashboard" element={<Dashboard />}></Route>
      <Route path="/tasks/:taskId" element={<Report />}></Route>
      <Route path="/tasks/:taskId/report" element={<Analytics />}></Route>
      <Route path="/accounts/settings" element={<Account />}></Route>
      <Route path="/accounts/credit" element={<BuyCredit />}></Route>
      <Route path="/accounts/orders" element={<Order />}></Route>
      <Route path="/reset-password" element={<ResetPassword />}></Route>
      <Route path="/beta/secure/dashboard" element={<Protected><SecureDashboard /></Protected>}></Route>
      <Route path="/beta/secure/dashboard/users" element={<Protected><SecureDashboard /></Protected>}></Route>

    </Routes>
  </BrowserRouter>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
