import React, { useEffect, useState } from "react";
import { AccountServices } from "../../../components/API";
import getUser from "./UserMap";


const Tasks = () => {
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        AccountServices.get('console/tasks')
            .then((response) => setTasks(response.data.data))
            .catch((err) => console.log(err));
    }, [])

    return <>
         <table className="table table-condensed">
            <thead className="table-dark">
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>CreatedBy</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                { tasks.map((task, index) => {
        
                    return <tr key={index}>
                            <td>{ task.id }</td>
                            <td>{ task.name.split('-')[1]}</td>
                            <td>{ task.status==='pending' ?  <span className="badge rounded-pill text-bg-warning">Pending</span> : <span className="badge rounded-pill text-bg-success">Complete</span> }</td>
                            <td>{ getUser(task.userId) }</td>
                            <td>{ task.createdAt}</td>
                            <td>{ task.updatedAt}</td>
                            <td>
                                <div className="btn-group">
                                    <a href={`/tasks/${task.id}/report`} className="btn btn-sm btn-primary">View</a>
                                    {/* <div className="btn btn-sm btn-primary">Delete</div> */}
                                </div>
                            </td>
                        </tr>
                } )}
                
            </tbody>
        </table>
    </>
}

export default Tasks;