import axios from "axios";

const AccountServices = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 1000,
    headers: {
        'Accept': 'application/json',
        'Authorization': localStorage.hasOwnProperty('token') ? localStorage.getItem('token') : ''
    }
});


export {
    AccountServices
}