import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "../components/Payment";
import { AccountServices } from "../components/API";
import './BuyCredit.css'; // Importing the CSS file for styling

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);

const BuyCredit = () => {
    const [clientSecret, setClientSecret] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [proceed, setProceed] = useState(true);

    const [groups, setGroups] = useState([
        { "rate": 1, "value": "accessanalyzer", "label": "ACCESSANALYZER" },
        { "rate": 0, "value": "account", "label": "ACCOUNT" },
        { "rate": 1, "value": "acm", "label": "ACM" },
        { "rate": 1, "value": "apigateway", "label": "APIGATEWAY" },
        { "rate": 1, "value": "apigatewayv2", "label": "APIGATEWAYV2" },
        { "rate": 1, "value": "appstream", "label": "APPSTREAM" },
        { "rate": 1, "value": "autoscaling", "label": "AUTOSCALING" },
        { "rate": 1, "value": "awslambda", "label": "AWSLAMBDA" },
        { "rate": 1, "value": "cloudformation", "label": "CLOUDFORMATION" },
        { "rate": 1, "value": "cloudfront", "label": "CLOUDFRONT" },
        { "rate": 1, "value": "cloudtrail", "label": "CLOUDTRAIL" },
        { "rate": 1, "value": "cloudwatch", "label": "CLOUDWATCH" },
        { "rate": 1, "value": "codeartifact", "label": "CODEARTIFACT" },
        { "rate": 1, "value": "codebuild", "label": "CODEBUILD" },
        { "rate": 1, "value": "config", "label": "CONFIG" },
        { "rate": 1, "value": "directoryservice", "label": "DIRECTORYSERVICE" },
        { "rate": 1, "value": "dynamodb", "label": "DYNAMODB" },
        { "rate": 1, "value": "ec2", "label": "EC2" },
        { "rate": 1, "value": "ecr", "label": "ECR" },
        { "rate": 1, "value": "ecs", "label": "ECS" },
        { "rate": 1, "value": "efs", "label": "EFS" },
        { "rate": 1, "value": "eks", "label": "EKS" },
        { "rate": 1, "value": "elb", "label": "ELB" },
        { "rate": 1, "value": "elbv2", "label": "ELBV2" },
        { "rate": 1, "value": "emr", "label": "EMR" },
        { "rate": 1, "value": "glacier", "label": "GLACIER" },
        { "rate": 1, "value": "glue", "label": "GLUE" },
        { "rate": 1, "value": "guardduty", "label": "GUARDDUTY" },
        { "rate": 1, "value": "iam", "label": "IAM" },
        { "rate": 1, "value": "kms", "label": "KMS" },
        { "rate": 1, "value": "macie", "label": "MACIE" },
        { "rate": 1, "value": "opensearch", "label": "OPENSEARCH" },
        { "rate": 1, "value": "rds", "label": "RDS" },
        { "rate": 1, "value": "redshift", "label": "REDSHIFT" },
        { "rate": 1, "value": "route53", "label": "ROUTE53" },
        { "rate": 1, "value": "s3", "label": "S3" },
        { "rate": 1, "value": "sagemaker", "label": "SAGEMAKER" },
        { "rate": 1, "value": "secretsmanager", "label": "SECRETSMANAGER" },
        { "rate": 1, "value": "securityhub", "label": "SECURITYHUB" },
        { "rate": 1, "value": "shield", "label": "SHIELD" },
        { "rate": 1, "value": "sns", "label": "SNS" },
        { "rate": 1, "value": "sqs", "label": "SQS" },
        { "rate": 1, "value": "ssm", "label": "SSM" },
        { "rate": 1, "value": "trustedadvisor", "label": "TRUSTEDADVISOR" },
        { "rate": 1, "value": "vpc", "label": "VPC" }, 
        { "rate": 1, "value": "workspaces", "label": "WORKSPACES" }
    ]);

    const [totalAmount, setTotalAmount] = useState(0); // Renamed from 'total' to 'totalAmount'
    const [selectAll, setSelectAll] = useState(false);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const calculateTotal = () => {
        setProceed(false);
        AccountServices.post("/secret", {
            items: cartItems,
            name: '',
            address: {
                name: 'Jenny Rosen',
                address: {
                    line1: '510 Townsend St',
                    postal_code: '98140',
                    city: 'San Francisco',
                    state: 'CA',
                    country: 'US',
                },
            }
        }, { timeout: 1000 * 5 })
            .then((data) => {
                setClientSecret(data.data.clientSecret);
                console.log(data.data.clientSecret);
            })
            .catch(err => console.log(err));
    };

    const isExistInCart = (value) => {
        let exists = cartItems.map((item) => item.value);
        return exists.includes(value);
    };

    const calculatePrice = () => {
        if (cartItems.length) {
            setTotalAmount(cartItems.map(item => item.rate).reduce((a, b) => a + b));
        } else {
            setTotalAmount(0);
        }
    };

    const addCart = (index, item) => {
        setCartItems([...cartItems, item]);
        calculatePrice();
    };

    const removeCart = (index, item) => {
        setCartItems(cartItems.filter((group) => group.value !== item.value));
    };

    useEffect(() => {
        if (cartItems.length > 0) {
            setTotalAmount(cartItems.map(item => item.rate).reduce((a, b) => a + b));
        }
    }, [cartItems]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (selectAll) {
            setCartItems([]);
        } else {
            setCartItems(groups);
        }
    };

    return (
        <>
            <Header />
            <div className="container">
                <div className="row" style={{ width: '100%' }}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                            <li className="breadcrumb-item">Buy Credit</li>
                        </ol>
                    </nav>
                    <h3 className="page-title">Buy Credits</h3>
                    <hr />
                    <div className="row">
                        <div className="col-md-6 cart-items">
                            <button className="btn btn-primary mb-3" onClick={handleSelectAll}>
                                {selectAll ? 'Deselect All' : 'Select All'}
                            </button>
                            <ul className="list-group">
                                {groups.map((item, index) => (
                                    <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                        {isExistInCart(item.value) ? (
                                            <>
                                                <strike>{item.label}</strike>
                                                <img src="https://cdn-icons-png.flaticon.com/128/845/845646.png" height='20px' alt="Added" />
                                            </>
                                        ) : (
                                            <>
                                                {item.label}
                                                <span className={item.rate <= 0 ? "badge bg-success" : "badge bg-primary"}>
                                                    {item.rate <= 0 ? 'Free' : `￡${item.rate}`}
                                                </span>
                                                <button className="btn btn-light btn-sm" onClick={() => addCart(index, item)}>Add</button>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-md-6 cart-summary">
                            <h5>Cart: <span className="badge bg-secondary float-end">{cartItems.length}</span></h5>
                            <ul className="list-group" style={{ height: '300px', overflowY: 'scroll', border: '2px dotted #ffc61a', backgroundColor: '#f8e3a4', padding: '10px' }}>
                                {cartItems.map((item, index) => (
                                    <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                        {item.label}
                                        {item.rate <= 0 && <span className="badge bg-success">Free</span>}
                                        <button className="btn btn-danger btn-sm" onClick={() => removeCart(index, item)}>
                                            <img src="/trash.png" width='20px' height='20px' alt="Remove" />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            {cartItems.length > 5 && (
                                <div className="text-center mt-2">
                                    <img src="https://www.shareicon.net/data/128x128/2015/09/20/643623_arrows_512x512.png" height='20px' alt="More Items" />
                                </div>
                            )}
                            <br />
                            {cartItems.length > 0 && proceed && (
                                <button className="btn btn-success float-end" onClick={calculateTotal}>Proceed</button>
                            )}
                            {clientSecret && cartItems.length > 0 && (
                                <Elements options={options} stripe={stripePromise}>
                                    <Payment total={totalAmount} />
                                </Elements>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BuyCredit;
