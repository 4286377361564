import React, { useState, useEffect} from "react";
import { AccountServices } from "../../../components/API";
import getUser from "./UserMap";

const Orders = (props) => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        AccountServices.get('console/orders')
            .then((response) => setOrders(response.data.data))
            .catch((err) => console.log(err));

    }, [])

    return <>
        <table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>ID</th>
                    <th>Status</th>
                    <th>User</th>
                    <th>Amount</th>
                    <th>PaymentIntent</th>
                    <th>Items</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                </tr>
            </thead>
            <tbody>
                { orders.map(order => 
                    <tr>
                        <td>{order.id}</td>
                        <td>{ order.status ? <span className="badge rounded-pill text-bg-success">Paid</span> : <span className="badge rounded-pill text-bg-warning">Fail</span> }</td>
                        <td>{ getUser(order.userId)}</td>
                        <td>£{order.amount}</td>
                        <td>{order.payment_intent}</td>
                        <td>{order.items.map(item => <span className="badge rounded-pill text-bg-primary">{item.label}</span>)}</td>
                        <td>{order.createdAt}</td>
                        <td>{order.updatedAt}</td>
                    </tr>
                )}
            </tbody>
        </table>
    </>
}

export default Orders;