import React from 'react'
import { Navigate } from 'react-router-dom';


const Protected = ({ children }) => {

    const admin = process.env.REACT_APP_ADMINS.split(',').map(Number);
    const user = localStorage.hasOwnProperty('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if (admin.includes(parseInt(user.user.id))) {
        return children;
    }
    return <Navigate to="/login" replace />



//   if (!isSignedIn) {
//     return <Navigate to="/login " replace />
//   }
//   return children
}
export default Protected