import axios from 'axios';
import React, { useState } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'; // Import your custom CSS file for additional styling

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null);
    const baseURL = `${process.env.REACT_APP_API_URL}/auth/login`;
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseURL, { email, password });
            if (response.data.accessToken) {
                localStorage.setItem('token', response.data.accessToken);
                localStorage.setItem('user', JSON.stringify(response.data));
                navigate('/dashboard'); // Use navigate instead of history.push
            } else {
                setMessage({
                    type: 'warning',
                    msg: 'Invalid credentials. Please check your email and password.',
                });
            }
        } catch (err) {
            setMessage({
                type: 'warning',
                msg: 'Error occurred. Please try again.',
            });
        }
    };

    return (
        <>
            <Header />
            <div className="login-container">
                <form className="login-form" onSubmit={handleLogin}>
                    <h3 className="text-center mb-4">Login</h3>
                    {message && (
                        <div className={`alert alert-${message.type}`} role="alert">
                            {message.msg}
                        </div>
                    )}
                    <div className="form-group">
                        <label className="form-label">Email address</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <small className="form-text text-muted">
                            We'll never share your email with anyone else.
                        </small>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-actions">
                        <a href="/reset-password" className="link">Forgot password?</a>
                        <a href="/register" className="link">Create Account</a>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-dark w-100"
                    >
                        Login
                    </button>
                </form>
            </div>
        </>
    );
};

export default LoginPage;
