import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Select from "react-select";
import { AccountServices } from "../components/API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Dashboard.css';  // Import custom CSS

const Dashboard = () => {
    const baseURL = `${process.env.REACT_APP_API_URL}`;
    const [awsAccessKeyId, setAwsAccessKeyId] = useState('');
    const [awsSecretAccessKey, setAwsSecretAccessKey] = useState('');
    const [awsSessionToken, setAwsSessionToken] = useState('sample token');
    const [tasks, setTasks] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [scan, setScan] = useState(false);
    const [message, setMessage] = useState('');
    const [groups, setGroups] = useState([
        { "rate": 3, "value": "account", "label": "ACCOUNT" },
        { "rate": 3, "value": "acm", "label": "ACM" },
        { "rate": 3, "value": "dynamodb", "label": "DYNAMODB" }
    ]);

    useEffect(() => {
        AccountServices.get('accounts/orders/available')
        .then((response) => {
            setGroups([...groups, ...response.data.data]);
        }).catch(err => console.log(err));
    }, []);

    const fetchTasks = () => {
        axios.get(`${baseURL}/tasks`, { headers: { Authorization: getToken() } })
            .then((response) => {
                setTasks(response.data);
            });
    }

    useEffect(() => {
        axios.get(`${baseURL}/tasks`, { headers: { Authorization: getToken() } })
            .then((response) => {
                setTasks(response.data);
            });
    }, []);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    }

    const getToken = () => {
        return localStorage.getItem('token');
    }

    const getUser = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.user;
    }

    const random = (length = 8) => {
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let str = '';
        for (let i = 0; i < length; i++) {
            str += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return str;
    };

    const check_expired = (date) => {
        const created_at = new Date(date);
        const current = new Date();
        const difference = current.getTime() - created_at.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays > 0;
    }

    const check_progress = (task) => {
        if (task.status === 'completed') {
            return <span className="badge badge-success">completed</span>;
        }
        if (check_expired(task.createdAt)) {
            return <span className="badge badge-danger">failed</span>;
        } else {
            return <span className="badge badge-warning">pending</span>;
        }
    }

    const startScan = () => {
        setScan(true);
        var user = getUser();
        var profile = `${user.username}-${random(10)}`;

        const payload = {
            id: user.id,
            name: profile,
            awsAccessKeyId: awsAccessKeyId,
            awsSecretAccessKey: awsSecretAccessKey,
            awsSessionToken: awsSessionToken,
            checks: selectedOption,
            userId: getUser().id,
            awsPath: '/home/ubuntu/.aws',
            status: 'pending'
        }

        axios.post(`${baseURL}/tasks/create`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken()
            }
        }).then((response) => {
            setTasks(tasks => [...tasks, response.data.task]);
            setScan(false);
            setAwsAccessKeyId('')
            setAwsSessionToken('')
            setAwsSecretAccessKey('')
            setMessage('We have queued your scan, we will mail you once report is ready.');
            watchUpdate(response.data.task, response.data.job);
        }).catch((err) => {
            console.log(err);
            setScan(false);
        });
    }

    const watchUpdate = (task, jobId) => {
        const eventSource = new EventSource(`${baseURL}/events/tasks/${task.id}/${jobId}`);
        eventSource.addEventListener('message', function(e) {
            let data = JSON.parse(e.data);
            if (data) {
                fetchTasks();
            }
        }, false);
    }

    if (getToken() != null) {
        return (
            <>
                <Header />
                <div className="container dashboard-container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-4">
                            <div className="card scan-card">
                                <div className="card-header">Start Scan</div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label">ACCESS KEY ID</label>
                                        <input type="text" value={awsAccessKeyId} onChange={(e) => setAwsAccessKeyId(e.target.value)} className="form-control" />
                                        {awsAccessKeyId === "" && <code>Access key is required</code>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">SECRET ACCESS KEY</label>
                                        <input type="text" value={awsSecretAccessKey} onChange={(e) => setAwsSecretAccessKey(e.target.value)} className="form-control" />
                                        {awsSecretAccessKey === "" && <code>Secret Access key is required</code>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Services</label>
                                        <Select
                                            defaultValue={[groups[0]]}
                                            options={groups}
                                            value={selectedOption}
                                            isMulti
                                            onChange={handleChange}
                                        />
                                        <div className="custom-links-container">
                                            <a href="/accounts/credit" className="custom-button-link">Buy Credit</a>
                                            <a href="https://acloud.services/how-to-get-aws-credentials/" className="custom-button-link">Get AWS Credentials</a>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        {awsAccessKeyId && awsSecretAccessKey && selectedOption !== '' &&
                                            <button onClick={startScan} className="btn btn-primary scan-button" disabled={scan}>
                                                {scan && <div className="spinner-border spinner-border-sm" role="status"></div>}
                                                Start Scan
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            {message !== '' &&
                                <div className="alert alert-info">
                                    {message}
                                </div>
                            }
                            <table className="table table-hover task-table">
                                <thead className="table-dark">
                                    <tr>
                                        <th>Scan ID</th>
                                        <th>Progress</th>
                                        <th>Status</th>
                                        <th>Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tasks.map((task, index) => (
                                        <tr key={index}>
                                            <td>#{task.id}</td>
                                            <td>{check_progress(task)}</td>
                                            <td>{task.createdAt}</td>
                                            <td>{task.createdAt}</td>
                                            <td>
                                                <a href={`/tasks/${task.id}/report`} className="btn btn-link">Report</a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>
        );
    } else {
        return (
            <>
                <div className="row">
                    <h3>Permission Denied</h3>
                    <p>You don't have permission to access this page.</p>
                </div>
                <ToastContainer />
            </>
        );
    }
}

export default Dashboard;
