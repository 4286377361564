import React from "react";
import './Header.css'; // Importing the CSS file

const Header = () => {
    const token = localStorage.getItem('token');

    const logout = () => {
        localStorage.clear();
        window.location.href = `/login`;
    };

    const getUser = () => {
        if (localStorage.getItem('user')) {
            return JSON.parse(localStorage.getItem('user'));
        }
    };

    return (
        <div>
            {token ? (
                <>
                    <nav className="header-nav">
                        <div className="container">
                            <a href="/" className="brand">
                                <img src="/acloud.png" alt="Acloud" className="brand-logo" />
                            </a>
                            <ul className="nav-list">
                                <li className="nav-item">
                                    <a href="/accounts/settings" className="nav-link">{getUser().user.username}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/accounts/orders" className="nav-link">Orders</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/accounts/settings" className="nav-link">Settings</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/beta/secure/dashboard" className="nav-link">Admin Console</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={logout} className="nav-link logout-link">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <header className="header-main">
                        <div className="container">
                            <form className="search-form" role="search">
                                <input type="search" className="search-input" placeholder="Search..." aria-label="Search" />
                            </form>
                        </div>
                    </header>
                </>
            ) : (
                <header className="header-main">
                    <div className="container">
                        <a href="/dashboard" className="brand">
                            <img src="/acloud.png" alt="Acloud" className="brand-logo" />
                        </a>
                    </div>
                </header>
            )}
        </div>
    );
};

export default Header;
