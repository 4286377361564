import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KPI from "../components/KPI";
import { AccountServices } from "../components/API";
import { styled } from '@mui/material/styles';

// Styled components
const CustomCard = styled(Paper)(({ theme }) => ({
    transition: 'transform 0.3s, box-shadow 0.3s',
}));

const CustomTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
}));

const CustomTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

function Analytics() {
    const params = useParams();
    const baseURL = `${process.env.REACT_APP_API_URL}`;
    const [report, setReport] = useState([]);
    const [columns, setColumns] = useState([]);
    const [stats, setStats] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        const getToken = () => localStorage.getItem('token');

        const processJSON = (report) => {
            return report.map(row => ({
                "Resource ID": row["ResourceId"],
                "Result": row["Status"],
                "Severity": row["Severity"],
                "ServiceName": row["ServiceName"],
                "SubServiceName": row["SubServiceName"],
                "Status": row["Status"],
                "StatusExtended": row["StatusExtended"],
                "AccountID": row["AccountId"],
                "Region": row["Region"],
                "Compliance": row["CheckType"].join(', '),
                "Service": row["Service"],
                "CheckID": row["CheckID"],
                "Check Title": row["CheckTitle"],
                "Check Output": row["Message"],
                "CIS Level": row["Level"],
                "CAF Epic": row["CAF Epic"],
                "Risk": row["Risk"],
                "Categories": row["Categories"]
            }));
        };

        axios.get(`${baseURL}/tasks/${params.taskId}/report`, { headers: { Authorization: getToken() } })
            .then(response => {
                const data = processJSON(response.data.data);
                setReport(data);
                if (data.length > 0) {
                    setColumns(Object.keys(data[0]));
                }

                setStats({
                    "total_passed": data.filter(value => value.Result === 'PASS').length,
                    "total_failed": data.filter(value => value.Result === 'FAIL').length,
                    "high_risk": data.filter(value => value.Severity === 'high').length,
                    "critical_risk": data.filter(value => value.Severity === 'critical').length,
                    "low_risk": data.filter(value => value.Severity === 'low').length,
                    "medium_risk": data.filter(value => value.Severity === 'medium').length,
                    "total": data.length
                });
            });
    }, [baseURL, params.taskId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        AccountServices.post('accounts/email', { name, email, taskId: params.taskId }, { timeout: 1000 * 5 })
            .then(response => {
                console.log(response.data);
                setName('');
                setEmail('');
            }).catch(err => console.log(err));
    }

    const getLabel = (result) => {
        switch (result) {
            case 'PASS':
                return 'bg-success';
            case 'FAIL':
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    }

    const handleUpdate = (key, value) => {
        console.log(key, value);
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="row" style={{ width: '100%' }}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                        </ol>
                    </nav>
                    <div className="col-md-7">
                        <CustomCard className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-body">
                                <h5 className="card-title">Account ID: { report.length && report[0]["Account Number"]}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">CAF Epic: <span className="badge bg-primary">{ report.length && report[0]["CAF Epic"]}</span></h6>
                            </div>
                        </CustomCard>
                        <hr />
                        <div className="row">
                            <KPI getUpdate={handleUpdate} keyType={'Result'} valueType={'PASS'} title={'Total Passed'} value={stats.total_passed} total={stats.total} color={'bg-success'} data={report.filter((res) => res.Result === 'PASS')} />
                            <KPI getUpdate={handleUpdate} keyType={'Result'} valueType={'FAIL'} title={'Total Failed'} value={stats.total_failed} total={stats.total} color={'bg-warning'} data={report.filter((res) => res.Result === 'FAIL')} />
                            <KPI getUpdate={handleUpdate} keyType={'Severity'} valueType={'Critical'} title={'Critical Risk'} value={stats.critical_risk} total={stats.total} color={'bg-danger'} data={report.filter((res) => res.Severity === 'critical')} />
                            <KPI getUpdate={handleUpdate} keyType={'Severity'} valueType={'High'} title={'High Risk'} value={stats.high_risk} total={stats.total} color={'bg-danger'} data={report.filter((res) => res.Severity === 'high')} />
                            <KPI getUpdate={handleUpdate} keyType={'Severity'} valueType={'Medium'} title={'Medium Risk'} value={stats.medium_risk} total={stats.total} color={'bg-warning'} data={report.filter((res) => res.Severity === 'medium')} />
                            <KPI getUpdate={handleUpdate} keyType={'Severity'} valueType={'Low'} title={'Low Risk'} value={stats.low_risk} total={stats.total} color={'bg-info'} data={report.filter((res) => res.Severity === 'low')} />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Need Support? </h5>
                                <div className="row">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label>Name:</label>
                                            <input type={'text'} onChange={(e) => setName(e.target.value)} className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <label>Email:</label>
                                            <input type={'email'} onChange={(e) => setEmail(e.target.value)} className="form-control" required />
                                        </div>
                                        <br />
                                        <div className="form-group float-right" style={{ marginTop: '10px' }}>
                                            <button type="submit" className="btn btn-primary">Get Support</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <CustomTableHead>
                            <TableRow>
                                <TableCell sx={{ color: "#fff" }}><strong>Resource ID</strong></TableCell>
                                <TableCell sx={{ color: "#fff" }} align="right"><strong>Result</strong></TableCell>
                                <TableCell sx={{ color: "#fff" }} align="right"><strong>Severity</strong></TableCell>
                                <TableCell sx={{ color: "#fff" }} align="right"><strong>Risk</strong></TableCell>
                            </TableRow>
                        </CustomTableHead>
                        <TableBody>
                            {report.map((row) => (
                                <CustomTableRow key={row["Resource ID"]}>
                                    <TableCell component="th" scope="row">
                                        {row["Resource ID"]}
                                    </TableCell>
                                    <TableCell align="right"><span className={`badge ${getLabel(row["Result"])}`}>{row["Result"]}</span></TableCell>
                                    <TableCell align="right">{row["Severity"]}</TableCell>
                                    <TableCell align="right">{row["Risk"]}</TableCell>
                                </CustomTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

export default Analytics;