import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function Payment(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    let app_url;
    if (process.env.REACT_APP_ENV === 'dev') {
      app_url = "http://localhost:3001";
    } else {
      app_url = "https://app.acloud.services";
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${app_url}/accounts/orders`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <div className="container">
      <form id="payment-form" onSubmit={handleSubmit} className="p-4 border rounded bg-light shadow-sm">
        <PaymentElement id="payment-element" className="mb-3" />
        <h3 className="mb-3">Total: ￡{props.total}</h3>
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="btn btn-dark float-end"
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && (
          <div id="payment-message" className={`mt-3 alert ${message.includes("succeeded") ? "alert-success" : "alert-danger"}`}>
            {message}
          </div>
        )}
      </form>
    </div>
  );
}
