import React, { useState } from "react";
import Header from "../../components/Header";
import Orders from "./components/Orders";
import Tasks from "./components/Tasks";
import Users from "./components/Users";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SecureDashboard = () => {
    const [page, setPage] = useState('users');

    return <>
        <Header />
        <div className="container" style={{ width: '100%'}}>
            <div className="row">
                <div className="col-md-8">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a onClick={(e) => setPage('users')}  className={`nav-link ${page==='users' && 'active'}`} aria-current="page" href="#">Users</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={(e) => setPage('tasks')}  className={`nav-link ${page==='tasks' && 'active'}`} href="#">Tasks</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={(e) => setPage('orders')}  className={`nav-link ${page==='orders' && 'active'}`} href="#">Orders</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link disabled">Subscription</a>
                        </li>
                    </ul>
                </div>
                <br></br>
            </div>
            <div className="row">
                {/* <div className="col-12"> */}
                    { page==='users' && <Users />}
                    { page==='tasks' && <Tasks />}
                    { page==='orders' && <Orders />}
                {/* </div> */}
        
            </div>
        </div>
        <ToastContainer />
    </>
}

export default SecureDashboard;