import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../components/Header";

const baseURL = `${process.env.REACT_APP_API_URL}/auth/change-password`;

const Account = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState({});
  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    const getToken = () => {
      return localStorage.getItem('token');
    };

    axios.get(`${process.env.REACT_APP_API_URL}/accounts/purchases`, { headers: { Authorization: getToken() } })
      .then((response) => {
        setPurchases(response.data); // Ensure you set the correct data here
      })
      .catch((error) => {
        console.error("Error fetching purchases", error);
      });
  }, []);

  const submit = () => {
    if (password === newPassword) {
      const { email } = JSON.parse(localStorage.getItem("user")).user;
      axios.post(baseURL, {
        email: email,
        password: password
      }).then((response) => {
        if (response.data) {
          setMessage({
            "type": "success",
            "msg": "Password changed successfully!"
          });
        }
      }).catch((error) => {
        setMessage({
          "type": "danger",
          "msg": "Error changing password. Please try again."
        });
      });
    } else {
      setMessage({
        "type": "warning",
        "msg": "Passwords do not match."
      });
    }
  };

  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Settings</li>
            </ol>
          </nav>
        </div>

        <div className="row">
          <div className="col-md-6 mx-auto">
            <h3 className="mb-4">Change Password</h3>
            {message.hasOwnProperty("type") && (
              <div className={`alert alert-${message.type} alert-dismissible fade show`} role="alert">
                {message.msg}
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
            )}
            <div className="form-group mb-3">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                placeholder="Enter new password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="reEnterPassword">Re-Enter Password</label>
              <input
                type="password"
                className="form-control"
                id="reEnterPassword"
                placeholder="Re-enter new password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <button
              onClick={submit}
              className="btn btn-dark"
            >
              Change Password
            </button>
          </div>
        </div>

        {/* Optional table section */}
        <div className="row mt-5">
          <div className="col-md-12">
            <h4 className="mb-4">Purchase History</h4>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Item Name</th>
                  <th>Item Code</th>
                  <th>Status</th>
                  <th>Mode</th>
                  <th>Created</th>
                  <th>Modified</th>
                </tr>
              </thead>
              <tbody>
                {purchases.map((record, index) => (
                  <tr key={index}>
                    <td>{record.id}</td>
                    <td>{record.itemName}</td>
                    <td>{record.itemCode}</td>
                    <td>{record.status}</td>
                    <td>{record.mode}</td>
                    <td>{new Date(record.created).toLocaleDateString()}</td>
                    <td>{new Date(record.modified).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
