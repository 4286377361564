import axios from 'axios';
import React, {useState, useNavigation} from "react";
import Header from "../components/Header";

const ResetPassword = () => {

    localStorage.clear();

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const baseURL = `${process.env.REACT_APP_API_URL}/auth/reset`;

    const submit = () => {
        axios.post(baseURL, {
            email: email
        }).then((response) => {
            if (response.data) {
                setMessage(response.data.msg);
            } 
        });

    }
        
    return <>
       <Header/>
       <form className="col-md-3 offset-md-4">
        <h3>Reset Password</h3>
           { message ?  <div className="alert alert-success" role="alert">{ message }</div> : '' }
        
        <div className="mb-3">
            <label  className="form-label">Email address</label>
            <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}  id="exampleInputEmail1" aria-describedby="emailHelp"/>
            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
        </div>
        
        <button type="button" onClick={(e) => submit() }  className="btn btn-primary">Reset</button>
        <a href="/login" style={{ marginLeft: '20px'}}>Try Login</a>
        </form>
    </>
}

export default ResetPassword;