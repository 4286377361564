import axios from "axios";
import React, {useState, useEffect} from "react";
import Header from "../components/Header";
import { useParams } from 'react-router-dom';


const Report = (reportId) => {
    const params = useParams()
    const baseURL = `${process.env.REACT_APP_API_URL}`;
    const [report, setReport] = useState([]);
    const [columns, setColumns] = useState([]);
    const [risks, setRisks] = useState([]);
    useEffect(() => {

        const getToken = () => {
            return localStorage.getItem('token');
        }

        const processJSON = (report) => {
            let result = [];
            report.map((row, index) => {
                result.push({
                    "Resource ID": row["Resource ID"],
                    "Result": row["Status"], 
                    "Severity": row["Severity"],
                    "AccountID": row["Account Number"],
                    "Region": row["Region"],
                    "Compliance": row["Compliance"],
                    "Service": row["Service"],
                    "CheckID": row["Control"].split(/ (.*)/,2)[0],
                    "Check Title": row["Control"].split(/ (.*)/,2)[1],
                    "Check Output": row["Message"],
                    "CIS Level": row["Level"],
                    "CAF Epic": row["CAF Epic"],
                    "Risk": row["Risk"]
                })
            })

            return result;
        }

        axios.get(`${baseURL}/tasks/${params.taskId}/report`, { headers: { Authorization: getToken() } })
            .then((response) => {
                const data = processJSON(response.data.data);
                setReport(data);
                if (response.data.data.length > 0) 
                    setColumns(Object.keys(data[0]));       
            });
    }, []);

    return <>
        <Header/>
        <div className="container-fluid">
            <div className="row mt-3">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">Report Information</div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item text-center">
                                <a href="https://acloud.services/dashboard">
                                <img width="250px" src="/acloud.png" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <table className="table table-condensed table-striped">
                <thead className="thead-dark">
                    <tr>
                        { columns.map((col, index) => {
                            return <th style={{ fontSize: '12px'}} scope="col">{col}</th>
                        })}          
                    </tr>
                </thead>
                <tbody>
                    { report.map((row, i) => {
                        return <tr>
                            { columns.map((col, index) => {
                                return <td>{row[col]}</td>
                            })}
                            <td><button className="btn btn-primary">Help?</button></td>
                        </tr>
                    })}
                
                </tbody>
            </table>
            </div>
        </div>
    </>
}

export default Report;