import React from "react";


const Hero = () => {
    return <div>
        <div className="px-4 pt-5 my-5 text-center border-bottom">
            <h1 className="display-4 fw-bold">AWS Scan</h1>
            <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">See the security status on all of your AWS services in one place.</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                <a href="/register" className="btn btn-primary btn-lg px-4 me-sm-3">Create Account</a>
                <a href="/login" className="btn btn-outline-secondary btn-lg px-4">Login</a>

            </div>
            </div>
            <div className="overflow-hidden" style={{ "max-height": "30vh" }}>
            
            </div>
        </div>
    </div>
}


export default Hero;