import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "./Header";
import Hero from "./Hero";
const App = () => {
    const navigate = useNavigate();
    return localStorage.hasOwnProperty('token') ? <Navigate to="/dashboard"></Navigate>: (
            <div>
                <Header/>
                <Hero/>
            </div> ) 
}

export default App;