import React, { useEffect, useState } from "react";
import { AccountServices } from "../components/API";
import Header from "../components/Header";
import { useSearchParams } from "react-router-dom";

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [searchParams] = useSearchParams();

  const updatePayment = (payment_intent_id) => {
    AccountServices.post(`console/orders/${payment_intent_id}`)
      .then((response) => console.log(response.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // Update the payment complete status
    if (searchParams.get('payment_intent')) {
      updatePayment(searchParams.get('payment_intent'));
    }

    AccountServices.get('/accounts/orders')
      .then((response) => {
        setOrders(response.data.data);
      })
      .catch((err) => console.log(err));
  }, [searchParams]);

  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Orders</li>
            </ol>
          </nav>
        </div>
        <div className="row mb-3">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <a href="/accounts/credit" className="btn btn-warning">Buy Credit</a>
            {searchParams.get('status') === 'complete' && (
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                👏🏻 Thank you! Order completed successfully
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped table-bordered">
              <thead className="table-dark">
                <tr>
                  <th>ID</th>
                  <th>Paid</th>
                  <th>Status</th>
                  <th>Items</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td>#{order.id}</td>
                    <td>
                      <span className={`badge ${order.status ? 'bg-success' : 'bg-danger'}`}>
                        £{order.amount}
                      </span>
                    </td>
                    <td>
                      {order.status
                        ? <img width="20" src="https://freeiconshop.com/wp-content/uploads/edd/checkmark-flat.png" alt="Completed" />
                        : <img width="20" src="https://freeiconshop.com/wp-content/uploads/edd/cross-flat.png" alt="Not Completed" />}
                    </td>
                    <td>
                      {order.items.map((item, index) => (
                        <span key={index}>{item.label}{index < order.items.length - 1 ? ', ' : ''}</span>
                      ))}
                    </td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
