import React, { useEffect, useState } from "react";
import { AccountServices } from "../../../components/API";
import { toast } from 'react-toastify';
import axios from "axios";
import './Secure.css';


const Users = () => {
    const [users, setUsers] = useState([]);
    const userMap = new Map();
    const [current, setCurrent] = useState({});
    const [isVerified, setIsVerified] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const baseURL = `${process.env.REACT_APP_API_URL}/auth/reset`;




    useEffect(() => {
        AccountServices.get('console/users')
            .then((response) => {
                setUsers(response.data.data)
                response.data.data.forEach(user => {
                    userMap.set(user.id, user);
                });
                // console.log(response.data.data);
                localStorage.setItem('userMap', JSON.stringify(Object.fromEntries(userMap)));
            })
            .catch((err) => console.log(err));

    }, [])

    const selectUser = (a) => {
        setCurrent(a);
    }

    const reset_password = (e, u) => {
        console.log(u);
        axios.post(baseURL, {
                email: u.email
            }).then((response) => {
                if (response.data) {
                    toast(`${u.email} password has been reset, sent password to Email: ${u.email}`)
                    // setMessage(response.data.msg);
                } 
            });
    }


    const updateUser = (e, field) => {
        const temp = {[field]: e.target.value};
        
        let tempUser = current;
        switch (field) {
            case "username":
                tempUser.username = e.target.value;
                break;
            case "email":
                tempUser.email = e.target.value;
                break;
            case "is_verified":
                console.log("is_verified", e.target.value);
                temp.is_verified = e.target.checked;
                break;
            case "is_active":
                console.log("is_active", e.target.value);
                temp.is_active = e.target.checked
                break;
            default:
                break;
            
            setCurrent(tempUser);
        }
        
    }


    const toggleActive = (e, u) => {
        let temp = {is_active: u.is_active ? false : true};
        AccountServices.post(`console/users/${u.id}/status/active`, temp)
            .then((response) => {
                toast(`Your activation is ${ temp.is_active ? 'active': 'pending'}`)
                setCurrent(current => ({...current, ['is_active']: temp.is_active}))
                console.log(response.data)})
            .catch(err => console.log(err.message));
    }

    const toggleVerify = (e, u) => {
        let temp = {is_verified: u.is_verified ? false : true};
        AccountServices.post(`console/users/${u.id}/status/verification`, temp)
            .then(response => {
                toast(`Your verification is ${ temp.is_verified ? 'verified': 'pending'}`)
                setCurrent(current => ({...current, ['is_verified']: temp.is_verified}));
                console.log(response.data)})
            .catch(err => console.log(err.message));
    }
   

    return <>
        {/* <div className="row"> */}
            <div className={ (JSON.stringify(current) != '{}') ? 'col-8' : 'col-12' }>
            <table className="table table-bordered">
                <thead className="table-dark">
                    <tr>
                        <th>ID</th>
                        {/* <th>Username</th> */}
                        <th>Email</th>
                        <th>Verified</th>
                        <th>Active</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>

                    { users.map((user, index) => {
                        return <><tr key={index}>
                                <td>{ user.id }</td>
                                {/* <td>{ user.username}</td> */}
                                <td>{ user.email }</td>
                                <td>{ user.is_verified ? <p className="verified">verified</p> : <p className="not-verified">pending</p> }</td>
                                <td>{ user.is_active ? <p className="is_active">active</p> : <p className="not-active">pending</p>}</td>
                                <td>{ user.createdAt}</td>
                                <td>{ user.updatedAt}</td>
                                <td>
                                    <div className="btn-group">
                                        {/* <div className="btn btn-sm btn-info">Disable</div> */}
                                        <button className="btn btn-sm btn-primary" onClick={(e) => selectUser(user)}>Edit</button>
                                        {/* <button className="btn btn-sm btn-danger">Delete</button> */}
                                    </div>
                                </td>
                                
                            </tr>
                            
                        </>
                    } )}
                    
                </tbody>
            </table>
            </div>
            {/* { Object.keys(current).length > 0 &&  */}
            { (JSON.stringify(current) != '{}') &&
            
            <div  className={ (JSON.stringify(current) != '{}') && 'col-4'} >
                <div className="card">
                    <div className="card-header">
                        Edit
                    <button className="btn btn-warning float-end" onClick={(e) => setCurrent({})}>x</button>

                    </div>

                    <div className="card-body">
                        <div className="form-group">
                            <label>Email</label>
                            <input type={'text'} className={'form-control'} value={current.email} onChange={(e) => updateUser(e, 'email')} />
                        </div>
                        
                        <br />
                        <div className="form-group">
                            <label>Email Verification</label>
                            {/* <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox"  onClick={(e) => updateUser(e, 'is_verified')}/>
                                <label className="form-check-label">is Verified</label>
                            </div> */}
                            <br></br>
                            <div className="btn-group">
                                <button onClick={(e) => toggleVerify(e, current)} className={ current.is_verified===true ? 'btn btn-sm btn-success' : 'btn btn-sm btn-secondary'}>{ current.is_verified===true ? "Verified" : 'Not Verified'}</button>
                            </div>
                        </div>
                        <br/>
                        <div className="form-group">
                            <label>Account Activation</label>
                            <br/>
                            <div className="btn-group">
                            <button onClick={(e) => toggleActive(e, current)} className={ current.is_active===true ? 'btn btn-sm btn-success' : 'btn btn-sm btn-secondary'}>{ current.is_active===true ? "Active" : 'Not Active'}</button>
                            </div>
                        </div>
                        <div className="form-group">
                            {/* <button className="btn btn-primary btn-block float-end">Update</button> */}
                        </div>
                        <br />
                        <br />
                        <br />
                        <hr/>
                        <p>Reset Password and mail.</p>
                        <button onClick={(e) => reset_password(e, current) } className="btn btn-secondary float-end">Reset Password</button>
                    </div>
                </div>
  
            </div> }
            {/* } */}
    </>
}

export default Users;